<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="bot名称"> </el-table-column>
			<el-table-column label="bot类型">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 0">文章</span>
					<span v-if="scope.row.type == 1">活动</span>
				</template>
			</el-table-column>

			<el-table-column prop="update_time" label="更新时间"> </el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<span v-if="scope.row.status == 0">已保存未推送</span>
					<span v-if="scope.row.status == 1">推送成功</span>
					<span v-if="scope.row.status == 2">推送失败</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span
						v-if="scope.row.status != 1"
						class="pointer margin_left_10"
						@click="toEdit(scope.row)"
					>
						编辑
					</span>
					<span
						v-if="scope.row.status == 1"
						class="pointer margin_left_10"
						@click="toShow(scope.row)"
					>
						查看
					</span>
					<span class="pointer margin_left_10" @click="toCopy(scope.row)">
						复用
					</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { destroyactivity } from "@/api/article";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		toShow(row) {
			this.$router.push({
				path:
					row.type == 0
						? "/information/botArticleDetail"
						: "/information/botEventDetail",
				query: { id: row.id, type: "detail" },
			});
		},
		toEdit(row) {
			this.$router.push({
				path:
					row.type == 0
						? "/information/botArticleDetail"
						: "/information/botEventDetail",
				query: { id: row.id, type: "edit" },
			});
		},
		toCopy(row) {
			this.$router.push({
				path:
					row.type == 0
						? "/information/botArticleDetail"
						: "/information/botEventDetail",
				query: { id: row.id, type: "copy" },
			});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
